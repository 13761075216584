import { Box } from '@mui/system';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import ConfirmDeletionModal from '../../atomic-molecules/Popovers/ConfirmDeletionModal';

/**
 * NetNet Table component.
 *
 * @param {Array} rows - Rows.
 * @param {Array} columns - columns for the data grid pro table.
 * @param {Array} sortModel - Its an Array if the sorting is being used anywhere in the table.
 * @param {number} screenHeight - screen height of each screen, dynamical height
 * @param {object} sx - For Styling
 * @param {Array} selectRow - Select Row includes the row is being selected and it is require when you have checkbox in the table
 * @param {function} setSelectRow - For selecting the particular row
 * @param {function} handleColumnVisibilityChange - function for turning on and off the table column
 * @param {function} handleRowClassName - function for getting the class name
 * @param {function} handleColumnOrderChange - function for the when user change the column order like swap one column with another
 * @param {object} components - If you want to have different icons in the columns for sorting usage as below
 * components={{
                 ColumnSortedAscendingIcon: IoMdArrowDropdown,
                 ColumnSortedDescendingIcon: IoMdArrowDropup,
               }}
 * @param {boolean} checkboxSelection - To Define the checkbox is required or not
 * @param {number} rowHeight - few table has big row height so we can pass this as well
 * @param {number} headerHeight - for header's height.
 * @param {object} componentsProps - it uses the styling in order to hide the menu and checkbox from manage columns usage like below 
 * componentsProps={{
            panel: {
              sx: {
                [`& .MuiDataGrid-columnsPanel > div:first-child`]: {
                  display: 'none',
                },
                [`& .MuiDataGrid-columnsPanel > div:last-child`]: {
                  display: 'none',
                },
              },
            },
          }}
 * @param {object} initialState - The columns you want to hide by default in start, usage as below 
          initialState={{
            columns: {
              address:false,
            },
          }}
  * @param {boolean} sureDelete - In order to open the Delete Modal
 * @param {function} setSureDelete - To close the Delete Modal from Modal it self by clicking cancel
 * @param {object} currentTarget - Data To be shown in the modal.
 */

function NetNetTable({
  rows,
  columns,
  sortModel,
  setSortModel,
  screenHeight,
  selectRow,
  setSelectRow,
  handleColumnVisibilityChange,
  handleRowClassName,
  handleColumnOrderChange,
  sx,
  components,
  checkboxSelection,
  rowHeight,
  headerHeight,
  componentsProps,
  initialState,
  sureDelete,
  setSureDelete,
  deleteEndPoint,
  itemName,
  paragraph,
  currentTargetData,
  multipleDelete,
  multipleDeleteData,
}) {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: screenHeight - 0,
        maxHeight: screenHeight - 0,
        borderRadius: '8px',
      }}
    >
      {sureDelete && (
        <ConfirmDeletionModal
          setSureDelete={setSureDelete}
          deleteEndPoint={deleteEndPoint}
          itemName={itemName}
          paragraph={paragraph}
          currentTargetData={currentTargetData}
          multipleDelete={multipleDelete}
          multipleDeleteData={multipleDeleteData}
        />
      )}
      <DataGridPro
        rows={rows}
        columns={columns}
        sortModel={sortModel || []}
        onSortModelChange={(model) => {
          if (setSortModel) setSortModel(model);
        }}
        rowHeight={rowHeight ?? 80}
        headerHeight={headerHeight}
        disableColumnResize={false}
        onColumnOrderChange={(model) => {
          if (handleColumnOrderChange) handleColumnOrderChange(model);
        }}
        initialState={initialState}
        sx={sx}
        checkboxSelection={checkboxSelection}
        rowSelectionModel={selectRow || []}
        onRowSelectionModelChange={(id) => {
          if (setSelectRow) setSelectRow(id);
        }}
        onColumnVisibilityModelChange={(model) => {
          if (handleColumnVisibilityChange) handleColumnVisibilityChange(model);
        }}
        onColumnVisibilityChange={(model) => {
          if (handleColumnVisibilityChange) handleColumnVisibilityChange(model);
        }}
        getCellClassName={(params) => {
          if (handleRowClassName) return handleRowClassName(params);
          return ''; // Default return value
        }}
        onSelectionModelChange={(newSelection) => {
          handleSelectionModelChange(newSelection);
        }}
        rowClassName={(params) => {
          const isSelected = selectedRows.includes(params.row.id);
          return isSelected ? 'selected-row' : '';
        }}
        components={components || {}}
        pagination={false}
        localeText={{
          noRowsLabel: (
            <h4
              style={{
                color: '#575757',
                fontFamily: 'Apple-System, Arial, Helvetica',
              }}
            >
              It looks like there’s nothing here. Try changing your filters to
              see more results.
            </h4>
          ),
        }}
        checkboxSelectionVisibleOnly={false}
        componentsProps={componentsProps}
      />
    </Box>
  );
}

export default NetNetTable;

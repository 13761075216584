import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Drawer from '@mui/material/Drawer';
import { Avatar, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import AvatarGroup from '@mui/material/AvatarGroup';

import moment from 'moment/moment';
import Board from 'react-trello';
import Popup from 'reactjs-popup';
import Bounce from 'react-reveal/Bounce';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import { getQuickTask } from './proRedux';
import { NetKanbanPrograss, NetProgress } from '../../Componenets/ProgressBar';
import CreateTask from './CreateTask';
// eslint-disable-next-line
import { FindIcon, TaskDetail } from './_module';

import QuickHelpVideos from './ProjectDet/QuickHelpVideos';
import calculateTotalTimeObtained from '../../utils/calculateTotalTimeObtained';
import TitleBar from '../../Componenets/atomic-molecules/Headers/TitleBar';
import { FilterBar } from '../../Componenets/atomic-molecules/Headers/FilterBar';
import GroupByTable from '../../Componenets/atomic-organisms/Tables/GroupByTable';
import NetNetTable from '../../Componenets/atomic-organisms/Tables/NetNetTable';

import moreIco from '../Opportunity/assets/moreIco.png';
import { BrowserStorageManager } from '../../cache/BrowserStorageManager';
import {
  loadColumnsFromLocalStorage,
  saveColumnsToLocalStorage,
} from '../../cache/columnsPersistence';
import { getTaskSectionTitle } from '../../utils/getTaskSectionTitle';

function CustomCard({
  title,
  description,
  label,
  otherText,
  assignee,
  taskType,
  netTotal,
  fullTotal,
  total_tracked,
  eta,
  status,
  checked,
  task_project,
}) {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  const [cardClicked, setCardClicked] = useState(checked);
  const handleCardClick = () => {
    setCardClicked(!cardClicked);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        padding: '10px 10px',
        cursor: 'pointer',
        margin: '16px',
        width:
          screenSize >= 1750
            ? '360px'
            : screenSize >= 1650 && screenSize <= 1749
              ? '320px'
              : screenSize >= 1300
                ? '240px'
                : screenSize < 1300
                  ? '190px'
                  : 'auto',
      }}
    >
      <div onClick={handleCardClick}>
        {title && (
          <div
            style={{
              backgroundColor:
                taskType === 'quick_task' ? '#e0e0e0' : '#e0b4fa33',
              borderStartStartRadius: '8px',
              borderStartEndRadius: '8px',
              padding: '14px 16px 10px 14px',
              fontWeight: 700,
              fontSize: '17px',
            }}
          >
            <p>{title}</p>
            {task_project !== undefined && (
              <Box
                sx={{
                  color: 'black',
                  fontSize: '13px',
                  marginRight: '5px',
                  fontWeight: 550,
                }}
              >
                <SubdirectoryArrowRightRoundedIcon sx={{ fontSize: '17px' }} />{' '}
                {task_project.toUpperCase()}
              </Box>
            )}
          </div>
        )}
      </div>

      {cardClicked && (
        <div
          style={{
            transition: 'max-height 0.3s ease-in-out',
            maxHeight:
              description || otherText || label || status || assignee
                ? '1000px'
                : '0',
            overflow: 'hidden',
          }}
        >
          {description && (
            <div
              style={{
                padding: '14px 16px 10px 14px',
                fontWeight: 500,
                backgroundColor: '#f2f2f2',
                maxHeight: '1000px',
                overflow: 'hidden',
              }}
            >
              <p
                style={{ fontSize: '16px' }}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          )}
          {otherText && (
            <div
              style={{
                backgroundColor: '#e7deff',
                color: '#711fff',
                padding: '4px 8px 4px 8px',
                borderRadius: '8px',
                marginTop: '14px',
                fontWeight: 600,
                fontSize: '0.98rem',
                maxHeight: '1000px',
                overflow: 'hidden',
              }}
            >
              <p>{otherText}</p>
            </div>
          )}
          {label && (
            <div
              style={{
                padding: '4px 8px 4px 8px',
                backgroundColor: '#e0dede',
                width: '70%',
                borderRadius: '8px',
                marginTop: '10px',
                color: '#dc143c',
                maxHeight: '1000px',
                overflow: 'hidden',
              }}
            >
              <span style={{ fontSize: '0.92rem', fontWeight: 600 }}>
                {' '}
                {label}
              </span>
            </div>
          )}

          {status && (
            <div
              style={{
                fontWeight: 'bold',
                width: '100%',
                borderRadius: '8px',
                marginTop: '10px',
                maxHeight: '1000px',
                overflow: 'hidden',
              }}
            >
              <Tooltip title="Represents the total duration">
                <div className="td">
                  <NetKanbanPrograss
                    status={status}
                    obt={total_tracked}
                    eta={fullTotal ?? netTotal ?? eta}
                  />
                </div>
              </Tooltip>
            </div>
          )}

          {assignee ? (
            <Box
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                maxHeight: '1000px',
                overflow: 'hidden',
              }}
            >
              <AvatarGroup
                max={3}
                sx={{
                  justifyContent: 'flex-end',
                  textAlign: 'center',
                  paddingLeft: '15px',
                  '& .css-sxh3gq-MuiAvatar-root-MuiAvatarGroup-avatar': {
                    height: '19px',
                    width: '19px',
                    fontSize: '14px',
                  },
                  '& .css-rfuczr': {
                    height: '19px',
                    width: '19px',
                    fontSize: '14px',
                  },
                  '& .css-1llrwy8': {
                    height: '19px',
                    width: '19px',
                    fontSize: '14px',
                  },
                }}
              >
                {Array.isArray(assignee) && assignee.length
                  ? assignee
                      ?.filter(
                        (item) =>
                          item?.first_name !== 'First Name' &&
                          item?.first_name !== 'First Name ',
                      )
                      .map((item) => {
                        return (
                          <Avatar
                            key={item?.id ?? item?.first_name}
                            sx={{ height: '19px', width: '19px' }}
                            alt={item?.first_name}
                            src={
                              item?.avatar
                                ? item?.avatar
                                : 'static/images/avatar/1.jpg'
                            }
                          />
                        );
                      })
                  : null}
              </AvatarGroup>
            </Box>
          ) : null}
        </div>
      )}
    </div>
  );
}

function CustomLaneHeader({ title, realData }) {
  const tasksLength =
    realData.find((lane) => lane.title === title)?.cards.length || 0;

  return (
    <div
      style={{
        backgroundColor:
          title === 'Backlog'
            ? '#e0b4fa'
            : title === 'Need More Work'
              ? '#f0e4b9'
              : title === 'Inprogress'
                ? '#79d0ed'
                : title === 'Stuck'
                  ? '#ffbd85'
                  : title === 'Completed'
                    ? '#56d6a0'
                    : title === 'Submitted for Review'
                      ? '#e7deff'
                      : title === 'Archive'
                        ? '#51b7c9'
                        : null,
        padding: '8px 23px 8px 23px',
        borderStartStartRadius: '8px',
        borderStartEndRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom:
          title === 'Backlog'
            ? '4px solid #65099c'
            : title === 'Need More Work'
              ? '4px solid #c49a02'
              : title === 'Inprogress'
                ? '4px solid #0000FF'
                : title === 'Stuck'
                  ? '4px solid #bb5805'
                  : title === 'Completed'
                    ? '4px solid #075433'
                    : title === 'Submitted for Review'
                      ? '4px solid #711fff'
                      : title === 'Archive'
                        ? '2px solid #51b7c9'
                        : null,
      }}
    >
      <h5>{title}</h5>
      <div
        style={{
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          fontSize: '14px',
          padding: '3px',
          background: '#fff',
          color: 'black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {tasksLength}
      </div>
    </div>
  );
}

const darkTypography = {
  color: '#310085',
  textTransform: 'uppercase',
  fontWeight: '600',
  fontFamily: 'Open Sans',
  fontSize: '1.2rem',
};
export { darkTypography };

const calculateTotalDurationObtained = (timeTrackingArray) => {
  let totalDuration = 0;

  if (Array.isArray(timeTrackingArray) && timeTrackingArray.length > 0) {
    timeTrackingArray.forEach((item) => {
      // Parse the tracked_time_hours string into hours, minutes, and seconds
      const [hours, minutes, seconds] =
        typeof item?.tracked_time === 'string'
          ? item.tracked_time.split(':').map(Number)
          : [0, 0, 0];

      // Convert hours, minutes, and seconds into total hours
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      const totalHoursForItem = totalSeconds / 3600;

      // Add to the total duration accumulator
      totalDuration += totalHoursForItem;
    });
  }
  return totalDuration;
};

const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'backlog', label: 'Backlog' },
  { value: 'inprogress', label: 'In Progress' },
  { value: 'review', label: 'Submitted for Review' },
  { value: 'needs_more_work', label: 'Needs more Work' },
  { value: 'stuck', label: 'Stuck' },
  { value: 'completed', label: 'Completed' },
  { value: 'archive', label: 'Archived' },
];

const timeTrackingOptions = [
  { value: 'all', label: 'All' },
  { value: 0, label: '(0%)' },
  { value: 80, label: '(0.1 - 80%)' },
  { value: 81, label: '(81 - 100%)' },
  { value: 100, label: '(100%+)' },
];

const dueTimeOptions = [
  { value: 'due_this_week', label: 'Due This Week' },
  { value: 'due_this_month', label: 'Due This Month' },
  { value: 'seven_days', label: 'Due Next 7 Days' },
  { value: 'fifteen_days', label: 'Due Next 15 Days' },
  { value: 'thirty_days', label: 'Due Next 30 Days' },
  { value: 'sixty_days', label: 'Due Next 60 Days' },
  { value: 'all', label: 'All Time' },
  { value: 'over_due', label: 'Over Due' },
];

const taskTypeOptions = [
  { value: 'all', label: 'All' },
  { value: 'quick_task', label: 'Quick Tasks' },
  { value: 'project_task', label: 'Job Tasks' },
  { value: 'project_task_retainer', label: 'Retainer Tasks' },
];

const searchTasks = (tasks, searchQuery) => {
  return searchQuery
    ? tasks.filter((item) =>
        item?.task_name?.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : tasks;
};

export const applyAllFilters = (
  taskType,
  status,
  taskDue,
  trackedTask,
  searchQuery,
  myArr,
  setProjects,
  teamFilter, // only required in all tasks not in my tasks
  groupBy, // only required in all tasks not in my tasks
  workTypesFromGlobalState, // only required in all tasks not in my tasks
  Team, // only required in all tasks not in my tasks
  setGroupByData, // only required in all tasks not in my tasks
) => {
  let filteredArray = [...myArr];

  // Step 1: Task Type Filter
  if (taskType) {
    filteredArray = filteredArray.filter((item) =>
      taskType.includes(item.task_type),
    );
  }

  // Step 2: Status Filter
  if (status && status.length > 0) {
    filteredArray = filteredArray.filter((item) =>
      status.includes(item.status),
    );
  }

  // Step 3: Time Filter
  if (taskDue && taskDue !== 'all') {
    const today = moment();

    if (taskDue === 'due_this_week') {
      const startOfWeek = today.clone().startOf('week');
      const endOfWeek = today.clone().endOf('week');
      filteredArray = filteredArray.filter(
        (item) =>
          item.due_date &&
          (moment(item.due_date).isBetween(
            startOfWeek,
            endOfWeek,
            null,
            '[]',
          ) ||
            moment(item.due_date).isBefore(today)),
      );
    } else if (taskDue === 'due_this_month') {
      const startOfMonth = today.clone().startOf('month');
      const endOfMonth = today.clone().endOf('month');
      filteredArray = filteredArray.filter(
        (item) =>
          item.due_date &&
          (moment(item.due_date).isBetween(
            startOfMonth,
            endOfMonth,
            null,
            '[]',
          ) ||
            moment(item.due_date).isBefore(today)),
      );
    } else if (taskDue === 'seven_days') {
      filteredArray = filteredArray.filter(
        (item) =>
          item.due_date &&
          (moment(item.due_date).isBetween(
            today,
            today.clone().add(7, 'days'),
            null,
            '[]',
          ) ||
            moment(item.due_date).isBefore(today)),
      );
    } else if (taskDue === 'fifteen_days') {
      filteredArray = filteredArray.filter(
        (item) =>
          item.due_date &&
          (moment(item.due_date).isBetween(
            today,
            today.clone().add(15, 'days'),
            null,
            '[]',
          ) ||
            moment(item.due_date).isBefore(today)),
      );
    } else if (taskDue === 'thirty_days') {
      filteredArray = filteredArray.filter(
        (item) =>
          item.due_date &&
          (moment(item.due_date).isBetween(
            today,
            today.clone().add(30, 'days'),
            null,
            '[]',
          ) ||
            moment(item.due_date).isBefore(today)),
      );
    } else if (taskDue === 'sixty_days') {
      filteredArray = filteredArray.filter(
        (item) =>
          item.due_date &&
          (moment(item.due_date).isBetween(
            today,
            today.clone().add(60, 'days'),
            null,
            '[]',
          ) ||
            moment(item.due_date).isBefore(today)),
      );
    } else if (taskDue === 'over_due') {
      filteredArray = filteredArray.filter(
        (item) => item.due_date && moment(item.due_date).isBefore(today),
      );
    }
  }

  // Step 4: Tracked Time Filter
  if (trackedTask) {
    filteredArray = filteredArray.filter((item) => {
      const totalTrackedTime = item.time_tracking.reduce((total, entry) => {
        if (entry.tracked_time) {
          const [hours, minutes, seconds] = entry.tracked_time
            .split(':')
            .map(Number);
          return total + hours + minutes / 60 + seconds / 3600;
        }
        return total;
      }, 0);

      const totalHours =
        item.task_type === 'project_task'
          ? item.assignee_task.reduce((acc, task) => acc + task.hours, 0)
          : item.estimated_hours;

      const percentageTracked =
        totalHours > 0 ? (totalTrackedTime / totalHours) * 100 : 0;

      return trackedTask.some((threshold) => {
        if (threshold === 0 && percentageTracked === 0) return true;
        if (
          threshold <= 80 &&
          threshold > 0 &&
          percentageTracked <= threshold &&
          percentageTracked > 0
        )
          return true;
        if (
          threshold >= 81 &&
          threshold < 100 &&
          percentageTracked >= 81 &&
          percentageTracked < 100
        )
          return true;
        if (threshold >= 100 && percentageTracked >= 100) return true;
        return false;
      });
    });
  }

  if (teamFilter) {
    filteredArray = filteredArray.filter((item) =>
      Array.isArray(item.assignee_task) && item.assignee_task.length
        ? item.assignee_task.some((task) =>
            teamFilter.includes(task.assignee_user),
          )
        : false,
    );
  }

  if (searchQuery.length > 0) {
    filteredArray = searchTasks(filteredArray, searchQuery);
  }

  if (groupBy && groupBy === 'team') {
    const allTasksOrFltered = filteredArray;
    const individualAssignee =
      Array.isArray(Team?.member) &&
      Team?.member?.length &&
      Team?.member.map((item) => {
        const tasksWithAssignee = allTasksOrFltered.filter((ele) =>
          ele?.assignee_task.some((int) => int?.assignee_user === item.id),
        );
        return {
          ...item,
          tasks: tasksWithAssignee,
        };
      });
    if (
      Array.isArray(teamFilter) &&
      teamFilter.length &&
      Array.isArray(individualAssignee) &&
      individualAssignee.length
    ) {
      const filteredAssigneeWithTeamGroupby = individualAssignee.filter(
        (item) => teamFilter.includes(item.id),
      );
      setGroupByData(filteredAssigneeWithTeamGroupby);
      return;
    }
    setGroupByData(individualAssignee);
    return;
  }

  if (groupBy && groupBy === 'worktype') {
    const MergedWorkTypes = [];
    workTypesFromGlobalState?.forEach((item) => {
      const exists = MergedWorkTypes.some(
        (existingWorktype) => existingWorktype.name === item.name,
      );

      if (!exists) {
        MergedWorkTypes.push(item);
      }
    });
    const UpdatedworktypeTasks = MergedWorkTypes.map((worktype) => {
      const allTasksOrFltered = filteredArray;
      const tasksWithWorktype = allTasksOrFltered.filter(
        (item) => item.task_worktype[0]?.name === worktype.name,
      );
      return {
        ...worktype,
        tasks: tasksWithWorktype,
      };
    });
    setGroupByData(UpdatedworktypeTasks);
    return;
  }
  setProjects(filteredArray);
};

const localStorageManager = new BrowserStorageManager(localStorage);

function QuickTasks({ toggleDrawer, setState, state, screenHeight }) {
  const dispatch = useDispatch();
  const [currentTarget, setCurrentTarget] = useState({});
  const token = useSelector((state) => state.auth.user.access);
  const [params, setParams] = useState();
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const Team = useSelector((state) => state.userTeam.team);
  const workTypesFromGlobalState = useSelector((state) => state.Misc.workTypes);
  const [args, setArgs] = useState([
    'all',
    'quick_task',
    'project_task',
    'project_task_retainer',
  ]);
  const [timeParams, setTimeParams] = useState('all');
  const [statusFilters, setStatusFilter] = useState([
    'inprogress',
    'review',
    'stuck',
    'needs_more_work',
  ]);
  const realProjects = useSelector((item) => item.projects.projects);
  const [selectedPeople, setSelectedPeople] = useState(() => {
    const initialMembers =
      Array.isArray(Team.member) && Team.member.length
        ? Team.member
            .filter((item) => item.first_name !== 'First Name')
            .map((item) => item.id)
        : [];
    return [0, ...initialMembers];
  });
  const [groupByTasks, setGroupByTasks] = useState('none');
  const [show, setShow] = useState(false);
  const [kanbanShow, setKanbanShow] = useState(false);
  const [counter, setCounter] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const myArr = useSelector((state) => state.projects.tasks);
  const currentUser = useSelector((state) => state.userData.userData);
  const sortingTasksKey = `TasksSortModelForUserID${currentUser.id}`;
  const manageColumnsTasksKey = `TasksColumnVisibilityForUserID${currentUser.id}`;

  const getStoredVisibility = () => {
    const storedSettings = localStorageManager.get(manageColumnsTasksKey);
    return storedSettings ?? {};
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    getStoredVisibility(),
  );

  useEffect(() => {
    localStorageManager.set(manageColumnsTasksKey, columnVisibilityModel);
  }, [columnVisibilityModel]);

  const handleColumnVisibilityChange = (newVisibilityModel) => {
    setColumnVisibilityModel(newVisibilityModel);
  };

  const [tasksSortModel, setTasksSortModel] = useState(
    localStorageManager.get(sortingTasksKey)
      ? localStorageManager.get(sortingTasksKey)
      : [
          {
            field: 'name',
            sort: 'asc',
          },
        ],
  );

  useEffect(() => {
    localStorageManager.set(sortingTasksKey, tasksSortModel);
  }, [tasksSortModel]);

  const [projects, setProjects] = useState([]);
  const [trackedTimeFilter, setTrackedTimeFilter] = useState([
    'all',
    0,
    80,
    81,
    100,
  ]);
  const [groupByData, setGroupByData] = useState([]);
  const [sureDelete, setSureDelete] = useState(false);

  useEffect(() => {
    dispatch(
      getQuickTask({
        access_token: token,
      }),
    );
  }, [show, params, currentTarget, counter, sureDelete]);

  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };
  const handleStatusFilterChange = (e) => {
    // eslint-disable-next-line
    let statusValue = e.target.value;
    if (statusValue.length === 0) {
      return;
    }
    const lastValue = statusValue[statusValue.length - 1];
    const unselected = statusFilters.filter(
      (item) => !statusValue.includes(item),
    );
    if (lastValue === 'all' && !statusFilters.includes('all')) {
      statusValue = statusOptions.map((item) => item.value);
      setStatusFilter(statusValue);
    } else if (unselected.includes('all') && statusFilters.includes('all')) {
      statusValue = ['backlog'];
      setStatusFilter(statusValue);
    } else if (
      statusOptions
        .filter((item) => item.value !== 'all')
        .every((it) => statusValue.includes(it.value))
    ) {
      statusValue = statusOptions.map((item) => item.value);
      setStatusFilter(statusValue);
    } else {
      statusValue = statusValue.filter((item) => item !== 'all');
      setStatusFilter(statusValue);
    }

    applyAllFilters(
      args,
      statusValue,
      timeParams,
      trackedTimeFilter,
      searchQuery,
      myArr,
      setProjects,
      selectedPeople,
      groupByTasks,
      workTypesFromGlobalState,
      Team,
      setGroupByData,
    );
  };

  useEffect(() => {
    applyAllFilters(
      args,
      statusFilters,
      timeParams,
      trackedTimeFilter,
      searchQuery,
      myArr,
      setProjects,
      selectedPeople,
      groupByTasks,
      workTypesFromGlobalState,
      Team,
      setGroupByData,
    );
  }, [searchQuery]);

  useEffect(() => {
    applyAllFilters(
      args,
      statusFilters,
      timeParams,
      trackedTimeFilter,
      searchQuery,
      myArr,
      setProjects,
      selectedPeople,
      groupByTasks,
      workTypesFromGlobalState,
      Team,
      setGroupByData,
    );
  }, [myArr, sureDelete]);

  const [isOpen, setIsOpen] = useState(false);

  const [columns, setColumns] = useState([
    {
      field: 'task_name',
      headerName: 'Task name',
      align: 'left',
      flex: 1.5,
      renderHeader: () => (
        <div className="tableHeader" style={{ padding: '0 0 0 1rem' }}>
          Task Name
        </div>
      ),
      renderCell: (params) => {
        const project_name =
          params.row.type !== null
            ? realProjects.find((item) => item.id === params.row.task_project)
                ?.title
            : '';
        return (
          <div
            className="td"
            style={{
              padding: '0 0 0 1rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              position: 'relative',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
            }}
            onClick={() => {
              setCurrentTarget(params.row);
              setParams(params.row);
              setShow(true);
            }}
          >
            <Tooltip title={params.value}>{params.value}</Tooltip>
            {params.row.type !== 'quick_task' ? (
              <Tooltip title={project_name}>
                <Box
                  sx={{
                    color: '#711FFF',
                    position: 'absolute',
                    top: '50px',
                    left: '15px',
                    fontSize: '13px',
                  }}
                >
                  <SubdirectoryArrowRightRoundedIcon
                    sx={{ fontSize: '17px' }}
                  />{' '}
                  {project_name?.toUpperCase()}
                </Box>
              </Tooltip>
            ) : (
              ''
            )}
          </div>
        );
      },
    },
    {
      field: 'company',
      headerName: 'Company Name',
      sortable: false,
      flex: 1.5,
      renderHeader: () => <div className="tableHeader">Company Name</div>,
      renderCell: (params) => {
        const taskCompany = currentCompanies.find(
          (comp) => Number(comp.id) === Number(params.value),
        )?.name;
        const projectCompany = currentCompanies.find(
          (comp) =>
            Number(comp.id) ===
            Number(
              realProjects.find((item) => item.id === params.row.task_project)
                ?.company,
            ),
        )?.name;

        return (
          <div
            className="td"
            onClick={() => {
              setCurrentTarget(params.row);
              setParams(params.row);
              setShow(true);
            }}
          >
            {params.value ? taskCompany : (projectCompany ?? '-------')}
          </div>
        );
      },
    },
    {
      field: 'assignee',
      headerName: 'Assignee',
      sortable: false,
      flex: 1.5,
      renderHeader: (params) => (
        <div
          className="tableHeader"
          onClick={() => {
            setCurrentTarget(params.row);
            setParams(params.row);
            setShow(true);
          }}
        >
          Assignee
        </div>
      ),
      renderCell: (params) => (
        <div
          className="td"
          style={{
            fontSize: '1rem',
          }}
          onClick={() => {
            setCurrentTarget(params.row);
            setParams(params.row);
            setShow(true);
          }}
        >
          {params.value && Team
            ? Team.member
                .filter((membraan) =>
                  Array.isArray(params.value) && params.value.length
                    ? params.value.some(
                        (ele) =>
                          Number(ele.assignee_user) === Number(membraan.id),
                      )
                    : Number(params.value) === Number(membraan.id),
                )
                .map(
                  (mem, index) =>
                    index === 0 && (
                      <div
                        key={mem?.id ?? mem.first_name}
                        className="dFlex"
                        style={{
                          gap: '0.5rem',
                          fontSize: '1rem',
                          fontWeight: '500',
                          fontFamily: 'Open Sans',
                        }}
                      >
                        <Avatar
                          src={mem.avatar}
                          alt={mem.first_name}
                          sx={{
                            width: '25px',
                            height: '25px',

                            border: 'none',
                            '& .css-1ytufz-MuiAvatarGroup-root': {
                              border: 'none',
                            },
                          }}
                        />
                        {mem.first_name} {mem.last_name}
                      </div>
                    ),
                )
            : '------'}{' '}
        </div>
      ),
    },
    {
      field: 'assinger',
      headerName: 'Assignor',
      sortable: false,
      flex: 1,
      renderHeader: (params) => (
        <div
          className="tableHeader"
          onClick={() => {
            setCurrentTarget(params.row);
            setParams(params.row);
            setShow(true);
          }}
        >
          Assignor
        </div>
      ),
      renderCell: (params) => (
        <div className="td">
          {params.value && Team
            ? Team.member
                .filter((membraan) => membraan.id === params.value)
                .map((mem) => (
                  <div
                    key={mem?.id ?? mem.first_name}
                    className="dFlex"
                    style={{
                      gap: '0.5rem',
                      fontSize: '1rem',
                      fontWeight: '500',
                      fontFamily: 'Open Sans',
                    }}
                  >
                    <Avatar
                      src={mem.avatar}
                      alt={mem.first_name}
                      sx={{
                        width: '25px',
                        height: '25px',
                        border: 'none',
                        '& .css-1ytufz-MuiAvatarGroup-root': {
                          border: 'none',
                        },
                      }}
                    />{' '}
                    {mem.first_name} {mem.last_name}
                  </div>
                ))
            : '------'}
        </div>
      ),
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
      renderHeader: (params) => (
        <div
          className="tableHeader"
          onClick={() => {
            setCurrentTarget(params.row);
            setParams(params.row);
            setShow(true);
          }}
        >
          Due Date
        </div>
      ),
      renderCell: (params) =>
        params.row.due_date ? (
          <div className="td" style={{ fontSize: '1rem', fontWeight: 600 }}>
            {`${params.row.due_date.split('T')[0]},${
              params.row.due_date.split(',')[1]
            }`}
          </div>
        ) : (
          <div className="td" style={{ fontSize: '1rem', fontWeight: 600 }}>
            -----
          </div>
        ),
    },
    {
      field: 'tracked_time',
      headerName: 'Time Tracked',
      sortable: false,
      // width: 200,
      flex: 1.5,
      renderHeader: () => <div className="tableHeader">Time Tracked</div>,
      renderCell: (params) => {
        const totalDurationObtained = calculateTotalTimeObtained(
          params.row.time_tracking,
        );

        const totalEtaHours =
          Array.isArray(params.row.assignee) && params.row.assignee.length
            ? params.row.assignee.reduce(
                (acc, obj) => acc + Number(obj.hours),
                0,
              )
            : 0;

        return (
          <Tooltip title="Represents Total Actual / Total Assigned">
            <div className="td">
              <NetProgress
                status={params.row.status}
                obt={totalDurationObtained || 0}
                eta={
                  params.row.type === 'quick_task'
                    ? params.row.eta
                    : totalEtaHours || 0
                }
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'right',
      sortable: false,
      flex: 1,
      renderHeader: () => (
        <div className="tableHeader" style={{ padding: '0 1rem 0 0' }}>
          Status
        </div>
      ),
      renderCell: (params) => (
        <div style={{ padding: '0 1rem 0 0' }}>
          <select
            className={
              params.row.status !== 'task_status'
                ? params.row.status
                : 'backlog'
            }
            onChange={async (e) => {
              setProjects((prev) => {
                const shallow_copy = [...prev.map((item) => ({ ...item }))];
                const int = shallow_copy.findIndex(
                  (ma) => Number(ma.id) === params.row.id,
                );
                shallow_copy[int].status = e.target.value;
                return shallow_copy;
              });

              const res = await fetch(
                `${ENDPOINT}/api/project/task/update/${params.row.id}/`,
                {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${token}`,
                  },
                  body: JSON.stringify({
                    task_name: params.row.task_name,
                    status: e.target.value,
                  }),
                },
              );
              if (res.ok) {
                dispatch(
                  getQuickTask({
                    access_token: token,
                  }),
                );
              }
            }}
            value={params.row.status}
          >
            <option value="backlog">Backlog</option>
            <option value="inprogress">In Progress</option>
            <option value="review">Submitted For Review</option>
            <option value="needs_more_work">Need more work</option>
            <option value="stuck">Stuck</option>
            <option value="completed">Completed</option>
            <option value="archive">Archived</option>
          </select>
        </div>
      ),
    },
    {
      field: 'btn',
      headerName: '',
      width: 10,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="td" style={{ padding: '0 1rem 0 0' }}>
          <Popup
            open={isOpen === params.row.id}
            trigger={
              <button type="button" className="noBorder">
                <img
                  onClick={() => setIsOpen(params.row.id)}
                  src={moreIco}
                  alt="more"
                />
              </button>
            }
            onClose={() => setIsOpen(false)}
            position="right center"
          >
            <ul className="popup" id={params.id}>
              <li
                className="popupChildren"
                onClick={() => {
                  setCurrentTarget(params?.row);
                  setParams(params.row);
                  setShow(true);
                }}
              >
                Edit
              </li>

              <li
                className="popupChildren LastChild"
                onClick={async () => {
                  setCurrentTarget(params.row);
                  setSureDelete(true);
                  setIsOpen(false);
                }}
                style={{ color: 'red' }}
              >
                Delete
              </li>
            </ul>
          </Popup>
        </div>
      ),
    },
  ]);

  const tasksColumnOrderStorageKey = `TasksTableColumnOrderForUserID${currentUser?.id}`;

  const handleColumnOrderChange = (newColumnOrder) => {
    const updatedColumns = [...columns.map((item) => ({ ...item }))];
    const columnToMove = updatedColumns[newColumnOrder.targetIndex];
    const find = columns.find(
      (item) => item.field === newColumnOrder.column.field,
    );

    updatedColumns[newColumnOrder.targetIndex] = find;
    updatedColumns[newColumnOrder.oldIndex] = columnToMove;
    saveColumnsToLocalStorage(tasksColumnOrderStorageKey, updatedColumns);
    setColumns(updatedColumns);
  };

  useEffect(() => {
    setColumns(
      loadColumnsFromLocalStorage(tasksColumnOrderStorageKey, columns),
    );
  }, []);

  const rows =
    projects?.length > 0
      ? projects?.map((item) => ({
          id: item.id,
          task_name: item.task_name,
          project: item.project,
          company: item.company,
          assignee:
            Team && Team.member
              ? Array.isArray(item.assignee_task) && item.assignee_task.length
                ? item.assignee_task
                : item.assignee[0]
              : null,
          assignee_task:
            Array.isArray(item.assignee_task) && item.assignee_task.length
              ? item.assignee_task
              : [],
          assinger: Team && Team.member ? item.created_by.id : null,
          status: item.status,
          time_tracking_start: item.time_tracking_start,
          time_tracking_end: item.time_tracking_end,
          tracked_time: item.tracked_time,
          description: item.description,
          eta: item.estimated_hours,
          alt_description: item.alt_description,
          notes: item.notes,
          target_start: item.target_start,
          due_date: item.due_date
            ? `${months[item.due_date.split('-')[1]]} ${
                item.due_date.split('-')[2]
              }, ${item.due_date.split('-')[0]}`
            : 0,
          time_remaing: Math.floor(
            (new Date(item.finish_date) - new Date()) / 1000,
          ),
          contact: item.contact,
          billable: item.billable,
          time_tracking: item.time_tracking,
          type: item.task_type,
          my_tasks: false,
          work_type:
            item.task_worktype && item.task_type !== 'quick_task'
              ? item.task_worktype
              : (item.quicktask_worktype ?? []),
          str_worktype:
            Array.isArray(item.task_worktype) &&
            item.task_worktype.length &&
            item.task_worktype[0]
              ? item.task_worktype[0]?.name
              : 'No Work type',
          task_project: item.task_project ? item.task_project : null,
        }))
      : false;

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const member = useSelector((item) => item.userTeam.team.member);
  const [formData, setFormData] = useState({});

  const defaultStatusForKanban = useMemo(
    () => [
      { dent: 'Backlog', identifier: 'backlog' },
      { dent: 'Inprogress', identifier: 'inprogress' },
      { dent: 'Stuck', identifier: 'stuck' },
      { dent: 'Completed', identifier: 'completed' },
    ],
    [],
  );

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    const find = projects.find((item) => Number(item.id) === Number(cardId));
    const newStatus = defaultStatusForKanban.find(
      (ele) => ele.dent === targetLaneId,
    );

    setProjects((prev) => {
      const shallow_copy = prev.map((item) => ({ ...item }));
      const int = shallow_copy.findIndex((ele) => Number(ele.id) === find.id);
      shallow_copy[int].status = newStatus.identifier;
      return [...shallow_copy];
    });
    // eslint-disable-next-line
    const resP = await fetch(
      `${ENDPOINT}/api/project/task/update/${find.id}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
        },
        body: JSON.stringify({
          task_name: find.task_name,
          status: newStatus.identifier,
        }),
      },
    );
  };

  const realData =
    Array.isArray(rows) && rows.length
      ? defaultStatusForKanban.map((status, index) => ({
          id: status.dent,
          title: status.dent,
          label: index,
          cards: rows
            .filter((ele) => ele.status === status.identifier)
            .map((ca) => ({
              id: ca?.id,
              title: ca?.task_name ?? '',
              description: ca.description ? ca.description : '',
              label: ca.due_date
                ? `Due On 
                  ${ca.due_date.split('T')[0]} 
                  ${ca.due_date.split(',')[1]}`
                : 'No due date available',
              otherText: ca.company
                ? currentCompanies.find(
                    (sa) => Number(sa.id) === Number(ca.company),
                  )?.name
                : '',
              assignee: Team.member.filter((membraan) =>
                Array.isArray(ca.assignee) && ca.assignee.length
                  ? ca.assignee.some(
                      (ele) =>
                        Number(ele.assignee_user) === Number(membraan.id),
                    )
                  : Number(ca.assignee) === Number(membraan.id),
              ),
              taskType: ca?.type ? ca?.type : 'quick_task',
              task_project: realProjects.find(
                (item) => item.id === ca.task_project,
              )?.title,
              netTotal:
                Array.isArray(ca?.work_type) && ca?.work_type.length
                  ? ca?.work_type.reduce((acc, wk) => acc + Number(wk.hours), 0)
                  : 0,
              fullTotal:
                Array.isArray(ca.assignee_task) && ca?.assignee_task.length
                  ? ca?.assignee_task.reduce(
                      (acc, obj) => acc + Number(obj.hours),
                      0,
                    )
                  : 0,
              total_tracked:
                Array.isArray(ca?.time_tracking) && ca?.time_tracking.length
                  ? ca?.time_tracking.reduce((total, acc) => {
                      const [hours, minutes] =
                        typeof acc.tracked_time_hours === 'string'
                          ? acc.tracked_time_hours.split(':')
                          : [0, 0];
                      const hoursInMinutes =
                        Number(hours) + Number(minutes) / 60; // Convert minutes to hours
                      return total + hoursInMinutes;
                    }, 0)
                  : 0,
              eta: ca.eta,
              status: ca.status,
            })),
        }))
      : [];

  const [checked, setChecked] = React.useState(false);
  const [showCanvas, setShowCanvas] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleFilterByTasks = (e) => {
    let selectedValue = e.target.value;
    if (selectedValue.length === 0) {
      return;
    }
    const lastValue = selectedValue[selectedValue.length - 1];
    const unselected = args.filter((item) => !selectedValue.includes(item));
    if (lastValue === 'all' && !args.includes('all')) {
      selectedValue = taskTypeOptions.map((item) => item.value);
      setArgs(selectedValue);
    } else if (unselected.includes('all') && args.includes('all')) {
      selectedValue = ['quick_task'];
      setArgs(selectedValue);
    } else if (
      selectedValue.includes('quick_task') &&
      selectedValue.includes('project_task') &&
      selectedValue.includes('project_task_retainer')
    ) {
      selectedValue = taskTypeOptions.map((item) => item.value);
      setArgs(selectedValue);
    } else {
      selectedValue = selectedValue.filter((item) => item !== 'all');
      setArgs(selectedValue);
    }
    applyAllFilters(
      selectedValue,
      statusFilters,
      timeParams,
      trackedTimeFilter,
      searchQuery,
      myArr,
      setProjects,
      selectedPeople,
      groupByTasks,
      workTypesFromGlobalState,
      Team,
      setGroupByData,
    );
  };

  const handleTimeFilterChange = (e) => {
    const valueArray = e.target.value;
    const lastValue =
      Array.isArray(valueArray) &&
      valueArray.length &&
      valueArray[valueArray.length - 1];
    setTimeParams(lastValue);
    applyAllFilters(
      args,
      statusFilters,
      lastValue,
      trackedTimeFilter,
      searchQuery,
      myArr,
      setProjects,
      selectedPeople,
      groupByTasks,
      workTypesFromGlobalState,
      Team,
      setGroupByData,
    );
  };

  const handleTeamChange = (data) => {
    let copiedTeam = data.target.value;
    if (copiedTeam.length === 0) {
      return;
    }
    const lastValue = copiedTeam[copiedTeam.length - 1];
    const unselected = selectedPeople.filter(
      (item) => !copiedTeam.includes(item),
    );
    if (lastValue === 0 && !selectedPeople.includes(0)) {
      const fullTeam = member
        .filter((item) => item.first_name !== 'First Name')
        .map((item) => item.id);
      copiedTeam = [0, ...fullTeam];
      setSelectedPeople(copiedTeam);
    } else if (unselected.includes(0) && selectedPeople.includes(0)) {
      const fullTeam = member
        .filter((item) => item.first_name !== 'First Name')
        .map((item) => item.id)[0];
      copiedTeam = [fullTeam];
      setSelectedPeople(copiedTeam);
    } else if (
      member
        .filter((item) => item.first_name !== 'First Name')
        .every((it) => copiedTeam.includes(it.id))
    ) {
      const fullTeam = member
        .filter((item) => item.first_name !== 'First Name')
        .map((item) => item.id);
      copiedTeam = [0, ...fullTeam];
      setSelectedPeople(copiedTeam);
      setSelectedPeople(copiedTeam);
    } else {
      copiedTeam = copiedTeam.filter((item) => item !== 0);
      setSelectedPeople(copiedTeam);
    }
    applyAllFilters(
      args,
      statusFilters,
      timeParams,
      trackedTimeFilter,
      searchQuery,
      myArr,
      setProjects,
      copiedTeam,
      groupByTasks,
      workTypesFromGlobalState,
      Team,
      setGroupByData,
    );
  };

  const handleTrackedTimeFilter = (e) => {
    let trackedTimeFiltervalue = e.target.value;
    if (trackedTimeFiltervalue.length === 0) {
      return;
    }
    const lastValue = trackedTimeFiltervalue[trackedTimeFiltervalue.length - 1];
    const unselected = trackedTimeFilter.filter(
      (item) => !trackedTimeFiltervalue.includes(item),
    );
    if (lastValue === 'all' && !timeTrackingOptions.includes('all')) {
      trackedTimeFiltervalue = timeTrackingOptions.map((item) => item.value);
      setTrackedTimeFilter(trackedTimeFiltervalue);
    } else if (
      unselected.includes('all') &&
      trackedTimeFilter.includes('all')
    ) {
      trackedTimeFiltervalue = [0];
      setTrackedTimeFilter(trackedTimeFiltervalue);
    } else if (
      timeTrackingOptions
        .filter((item) => item.value !== 'all')
        .every((it) => trackedTimeFiltervalue.includes(it.value))
    ) {
      trackedTimeFiltervalue = timeTrackingOptions.map((item) => item.value);
      setTrackedTimeFilter(trackedTimeFiltervalue);
    } else {
      trackedTimeFiltervalue = trackedTimeFiltervalue.filter(
        (item) => item !== 'all',
      );
      setTrackedTimeFilter(trackedTimeFiltervalue);
    }
    applyAllFilters(
      args,
      statusFilters,
      timeParams,
      trackedTimeFiltervalue,
      searchQuery,
      myArr,
      setProjects,
      selectedPeople,
      groupByTasks,
      workTypesFromGlobalState,
      Team,
      setGroupByData,
    );
  };

  const handleGroupByFilter = (e) => {
    const valueArray = e.target.value;
    const lastValue =
      Array.isArray(valueArray) &&
      valueArray.length &&
      valueArray[valueArray.length - 1];
    setGroupByTasks(lastValue);
    applyAllFilters(
      args,
      statusFilters,
      timeParams,
      trackedTimeFilter,
      searchQuery,
      myArr,
      setProjects,
      selectedPeople,
      lastValue,
      workTypesFromGlobalState,
      Team,
      setGroupByData,
    );
  };

  const sectionTitle = getTaskSectionTitle(args);

  const deleteEndPoint = `api/project/task/delete/${currentTarget?.id}/`;

  return Array.isArray(myArr) && myArr.length ? (
    <>
      <Bounce left>
        <section className="oppor-section">
          <TitleBar
            helpIconFunction={() => setShowCanvas(true)}
            buttonFunction={toggleDrawer('bottom', true)}
            buttonRequired={true}
            buttonTitle="Create Quick Task"
            sectionTitle={sectionTitle}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchBarRequired={true}
          />
          <Drawer
            sx={{
              height: '82vh',
              overflow: 'auto',
              '& .MuiDrawer-paper': {
                maxHeight: '82vh !important',
              },
            }}
            style={{
              overflowY: 'none', // Enable vertical scrolling
              scrollbarColor: 'transparent transparent', // For Firefox
              '&::-webkit-scrollbar': {
                display: 'none',
              },

              // Styles for Microsoft Edge
              '&::-ms-scrollbar': {
                width: 0,
                background: 'transparent transparent',
              },
            }}
            anchor="bottom"
            open={state.bottom}
            onClose={toggleDrawer('bottom', false)}
          >
            <CreateTask
              anchor="bottom"
              toggleDrawer={toggleDrawer}
              state={state}
              setState={setState}
              counter={counter}
              setCounter={setCounter}
            />
          </Drawer>

          <FilterBar
            setKanBanShow={setKanbanShow}
            kanBanShow={kanbanShow}
            checked={checked}
            handleChange={handleChange}
            filterDropdownArray={[
              {
                options: taskTypeOptions,
                labelId: 'task-filter',
                id: 'task-filter',
                selectedFilter: args,
                value: args,
                onChange: (e) => handleFilterByTasks(e),
                defaultValue: 'Task Type',
              },
              {
                labelId: 'time-filter',
                value: [timeParams],
                onChange: (e) => handleTimeFilterChange(e),
                selectedFilter: [timeParams],
                options: dueTimeOptions,
              },
              {
                labelId: 'status-filter',
                value: statusFilters,
                selectedFilter: statusFilters,
                onChange: (e) => handleStatusFilterChange(e),
                options: statusOptions,
                defaultValue: 'Status',
              },
              {
                labelId: 'color-filter',
                value: trackedTimeFilter,
                onChange: (e) => handleTrackedTimeFilter(e),
                options: timeTrackingOptions,
                selectedFilter: trackedTimeFilter,
                defaultValue: 'Time Tracking',
              },

              {
                labelId: 'team-filter',
                value: selectedPeople,
                onChange: (e) => handleTeamChange(e),
                options: [
                  { id: 0, first_name: 'All', last_name: null },
                  ...member,
                ],
                selectedFilter: selectedPeople,
                defaultValue: 'Team',
              },
              {
                labelId: 'groupby-filter',
                value: [groupByTasks],
                onChange: (e) => handleGroupByFilter(e),
                options: [
                  { value: 'none', label: 'Group by None' },
                  { value: 'team', label: 'Group by Team Member' },
                  { value: 'worktype', label: 'Group by Work Type' },
                ],
                selectedFilter: [groupByTasks],
              },
            ]}
          />

          {kanbanShow ? (
            <Box sx={{ width: '100%' }}>
              <Board
                data={{ lanes: realData }}
                draggable={true}
                handleDragEnd={handleCardMove}
                style={{
                  backgroundColor: '#F4F5F7',
                  width: '100%',
                  borderRadius: '4px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                laneStyle={{
                  background: '#e3e3e3',
                  margin: '10px',
                  borderRadius: '8px',

                  width:
                    screenSize >= 1650 && screenSize <= 1749
                      ? '340px'
                      : screenSize >= 1750
                        ? '380px'
                        : screenSize < 1300
                          ? '220px'
                          : '260px',
                }}
                components={{
                  // eslint-disable-next-line
                  LaneHeader: (props) => (
                    <CustomLaneHeader {...props} realData={realData} />
                  ),
                  // eslint-disable-next-line
                  Card: (props) => (
                    <CustomCard
                      {...props}
                      realProjects={realProjects}
                      checked={checked}
                    />
                  ),
                }}
              />
            </Box>
          ) : groupByTasks === 'none' ? (
            <NetNetTable
              rows={rows ?? []}
              columns={columns}
              sx={{
                minHeight: screenHeight - 225,
                maxHeight: screenHeight - 225,
                overflow: 'auto',
                borderRadius: '8px',
                background: '#fff',
              }}
              handleColumnOrderChange={handleColumnOrderChange}
              sureDelete={sureDelete}
              setSureDelete={setSureDelete}
              currentTargetData={currentTarget}
              sortModel={tasksSortModel}
              handleColumnVisibilityChange={handleColumnVisibilityChange}
              setSortModel={setTasksSortModel}
              initialState={{
                columns: {
                  columnVisibilityModel,
                },
              }}
              itemName="Task"
              deleteEndPoint={deleteEndPoint}
              paragraph="Are you sure you want to Delete this Task?"
              componentsProps={{
                panel: {
                  sx: {
                    [`& .MuiDataGrid-columnsPanel > div:last-child`]: {
                      display: 'none',
                    },
                  },
                },
              }}
            />
          ) : (
            <GroupByTable
              groupByData={groupByData}
              screenHeight={screenHeight}
              groupByTasks={groupByTasks}
              setParams={setParams}
              setCurrentTarget={setCurrentTarget}
              setShow={setShow}
              setGroupByData={setGroupByData}
              ENDPOINT={ENDPOINT}
              token={token}
              moreIco={moreIco}
              setSureDelete={setSureDelete}
              sureDelete={sureDelete}
              currentTargetData={currentTarget}
              itemName="Task"
              deleteEndPoint={deleteEndPoint}
              paragraph="Are you sure you want to Delete this Task?"
            />
          )}
          <QuickHelpVideos setShow={setShowCanvas} show={showCanvas} />
        </section>
      </Bounce>
      <TaskDetail
        formData={formData}
        currentTarget={currentTarget}
        show={show}
        setShow={setShow}
        setFormData={setFormData}
        setCurrentTarget={setCurrentTarget}
        token={token}
      />
    </>
  ) : (
    <section className="oppor-section">
      <TitleBar
        helpIconFunction={() => setShowCanvas(true)}
        buttonFunction={toggleDrawer('bottom', true)}
        buttonRequired={true}
        buttonTitle="Create Quick Task"
        sectionTitle={
          args.includes('all')
            ? 'All Tasks'
            : args.includes('quick_task') && args.includes('project_task')
              ? 'Quick & Job Tasks'
              : args.includes('project_task')
                ? 'Job Tasks'
                : args.includes('quick_task')
                  ? 'Quick Tasks'
                  : 'Tasks'
        }
      />
      <section className="myCenter" style={{ height: screenHeight - 155 }}>
        <article
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            padding: '1rem 0.5rem',
            justifySelf: 'flex-start',
            width: '620px',
            margin: '0rem 2rem',
          }}
        >
          <h3 className="txtCenter">
            You Don&apos;t Have Any Project Or Quick Tasks Yet.
          </h3>
          <p className="txtCenter">
            Project Based Tasks Are Added In The Individual Projects. Quick
            Tasks Are Added Here. All Of Your Project Based And Quick Tasks Will
            Display Here Once You Have Some.!
          </p>
          {['bottom'].map((anchor) => (
            <>
              <button
                type="button"
                style={{
                  width: '50%',
                  height: '48px',
                  border: 'none',
                  borderRadius: '7px',
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: '1rem',
                  fontWeight: '400',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#711fff',
                  alignSelf: 'center',
                  color: '#fff',
                  margin: '1rem 0',
                }}
                onClick={toggleDrawer(anchor, true)}
              >
                Click Here To Add A Quick Task
              </button>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                sx={{
                  height: '82vh',
                  overflow: 'auto',
                }}
              >
                <CreateTask
                  anchor={anchor}
                  toggleDrawer={toggleDrawer}
                  state={state}
                  setState={setState}
                  counter={counter}
                  setCounter={setCounter}
                />
              </Drawer>
            </>
          ))}
        </article>
      </section>
    </section>
  );
}

export {
  calculateTotalDurationObtained,
  taskTypeOptions,
  statusOptions,
  dueTimeOptions,
  timeTrackingOptions,
  QuickTasks,
};

import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { SubdirectoryArrowRightRounded } from '@mui/icons-material';

import { useSelector, useDispatch } from 'react-redux';

import Popup from 'reactjs-popup';

import dayjs from 'dayjs';

import calculateTotalTimeObtained from '../../../utils/calculateTotalTimeObtained';
import { NetProgress } from '../../ProgressBar';
import { getQuickTask } from '../../../pages/Projects/proRedux';
import '../../atomic-atoms/atomic-atoms.css';
import ConfirmDeletionModal from '../../atomic-molecules/Popovers/ConfirmDeletionModal';

const tableHeader = [
  'Task Name',
  'Company Name',
  'Assignee',
  'Assignor',
  'Due Date',
  'Time Tracked',
  'Status',
  '',
];
function TaskRow({
  tasks,
  setCurrentTarget,
  setParams,
  setShow,
  groupByTasks,
  setGroupByData,
  token,
  ENDPOINT,
  setSureDelete,
  moreIco,
}) {
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const realProjects = useSelector((item) => item.projects.projects);
  const Team = useSelector((state) => state.userTeam.team);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <TableBody>
      {tasks.map((task) => {
        const taskCompany = currentCompanies.find(
          (comp) => Number(comp.id) === Number(task.company),
        )?.name;
        const projectCompany = currentCompanies.find(
          (comp) =>
            Number(comp.id) ===
            Number(
              realProjects.find((item) => item.id === task.task_project)
                ?.company,
            ),
        )?.name;

        const totalDurationObtained = calculateTotalTimeObtained(
          task.time_tracking,
        );

        const totalEtaHours =
          Array.isArray(task.assignee_task) && task.assignee_task.length
            ? task.assignee_task.reduce(
                (acc, obj) => acc + Number(obj.hours),
                0,
              )
            : 0;

        const projectName =
          task.task_type === 'project_task'
            ? realProjects.find((item) => item.id === task.task_project)?.title
            : '';

        const handleStatusUpdate = async (e) => {
          setGroupByData((prev) => {
            const shallowCopy = prev.map((item) => ({
              ...item,
              tasks: item.tasks.map((taskItem) =>
                Number(taskItem.id) === Number(task.id)
                  ? { ...taskItem, status: e.target.value }
                  : taskItem,
              ),
            }));

            return shallowCopy;
          });

          const res = await fetch(
            `${ENDPOINT}/api/project/task/update/${task.id}/`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
              },
              body: JSON.stringify({
                task_name: task.task_name,
                status: e.target.value,
              }),
            },
          );
          if (res.ok) {
            dispatch(
              getQuickTask({
                access_token: token,
              }),
            );
          }
        };

        return (
          <TableRow key={task.id} sx={{ height: '80px' }}>
            {/* Section Header */}
            <TableCell className="task-cell">
              <div
                className="td task-header"
                onClick={() => {
                  setCurrentTarget(task);
                  setParams(task);
                  setShow(true);
                }}
              >
                <Tooltip title={task.task_name}>{task.task_name}</Tooltip>
                {task.task_type === 'project_task' ? (
                  <Tooltip title={projectName}>
                    <Box className="project-name">
                      <SubdirectoryArrowRightRounded
                        sx={{ fontSize: '17px' }}
                      />{' '}
                      {projectName?.toUpperCase()}
                    </Box>
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            </TableCell>
            {/* Company Name */}
            <TableCell className="task-cell">
              <div className="td">
                {task.company ? taskCompany : (projectCompany ?? '-------')}
              </div>
            </TableCell>

            {groupByTasks !== 'team' ? (
              <TableCell className="task-cell">
                <div
                  style={{
                    fontSize: '1rem',
                  }}
                  onClick={() => {
                    setCurrentTarget(task);
                    setParams(task);
                    setShow(true);
                  }}
                >
                  {task.assignee_task && Team
                    ? Team.member
                        .filter((member) =>
                          Array.isArray(task.assignee_task) &&
                          task.assignee_task.length
                            ? task.assignee_task.some(
                                (ele) =>
                                  Number(ele.assignee_user) ===
                                  Number(member.id),
                              )
                            : Number(task.assignee) === Number(member.id),
                        )
                        .map(
                          (mem, index) =>
                            index === 0 && (
                              <div
                                key={mem?.id ?? mem.first_name}
                                className="dFlex assignee-details"
                              >
                                <Avatar
                                  src={mem.avatar}
                                  alt={mem.first_name}
                                  sx={{
                                    width: '25px',
                                    height: '25px',

                                    border: 'none',
                                    '& .css-1ytufz-MuiAvatarGroup-root': {
                                      border: 'none',
                                    },
                                  }}
                                />
                                {mem.first_name} {mem.last_name}
                              </div>
                            ),
                        )
                    : '------'}{' '}
                </div>
              </TableCell>
            ) : null}

            {/* Assignor */}
            <TableCell className="task-cell">
              <div className="dFlex assignee-details">
                <Avatar
                  sx={{
                    width: '25px',
                    height: '25px',

                    border: 'none',
                    '& .css-1ytufz-MuiAvatarGroup-root': {
                      border: 'none',
                    },
                  }}
                  src={task.created_by.avatar ?? '/src'}
                  alt={task.created_by.first_name ?? 'A'}
                />
                {task.created_by.first_name} {task.created_by.last_name}
              </div>
            </TableCell>

            {/* Due Date */}
            <TableCell className="task-cell">
              <div className="td" style={{ fontSize: '1rem', fontWeight: 600 }}>
                {task.due_date
                  ? dayjs(task.due_date).format('MMM DD, YYYY')
                  : '--------'}
              </div>
            </TableCell>

            {/* Time Tracked */}
            <TableCell className="task-cell">
              <Tooltip title="Represents Total Actual / Total Assigned">
                <div className="td">
                  <NetProgress
                    status={task.status}
                    obt={totalDurationObtained || 0}
                    eta={
                      task.task_type === 'quick_task'
                        ? task.estimated_hours
                        : totalEtaHours || 0
                    }
                  />
                </div>
              </Tooltip>
            </TableCell>

            {/* Status */}
            <TableCell className="task-cell">
              <div style={{ padding: '0 1rem 0 0' }}>
                <select
                  className={
                    task.status !== 'task_status' ? task.status : 'backlog'
                  }
                  onChange={handleStatusUpdate}
                  value={task.status}
                >
                  <option value="backlog">Backlog</option>
                  <option value="inprogress">In Progress</option>
                  <option value="review">Submitted For Review</option>
                  <option value="needs_more_work">Need more work</option>
                  <option value="stuck">Stuck</option>
                  <option value="completed">Completed</option>
                  <option value="archive">Archived</option>
                </select>
              </div>
            </TableCell>
            <TableCell>
              <div className="td" style={{ padding: '0 1rem 0 0' }}>
                <Popup
                  open={isOpen === task.id}
                  trigger={
                    <button type="button" className="noBorder">
                      <img
                        onClick={() => setIsOpen(task.id)}
                        src={moreIco}
                        alt="more"
                      />
                    </button>
                  }
                  onClose={() => setIsOpen(false)}
                  position="right center"
                >
                  <ul className="popup" id={task.id}>
                    <li
                      className="popupChildren"
                      onClick={() => {
                        setCurrentTarget(task);
                        setShow(true);
                      }}
                    >
                      Edit
                    </li>

                    <li
                      className="popupChildren LastChild"
                      onClick={() => {
                        setCurrentTarget(task);
                        setSureDelete(true);
                        setIsOpen(false);
                      }}
                      style={{ color: 'red' }}
                    >
                      Delete Selected
                    </li>
                  </ul>
                </Popup>
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}

function TableHeader({ groupByTasks }) {
  return (
    <TableHead>
      <TableRow
        sx={{
          height: '56px',
          background: '#F7F7FA',
        }}
      >
        {tableHeader.map((item, index) => {
          return item === 'Assignee' && groupByTasks === 'team' ? null : (
            <TableCell
              sx={{ width: index === tableHeader.length - 1 ? '70px' : 'auto' }}
              className="groupby-table-header-cell"
              key={item}
            >
              {item}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function GroupByTable({
  groupByData,
  screenHeight,
  groupByTasks,
  setParams,
  setShow,
  setCurrentTarget,
  setGroupByData,
  token,
  ENDPOINT,
  setSureDelete,
  moreIco,
  sureDelete,
  deleteEndPoint,
  itemName,
  paragraph,
  currentTargetData,
}) {
  return (
    <div
      style={{
        height: screenHeight - 225,
        overflow: 'auto',
      }}
    >
      {Array.isArray(groupByData) &&
      groupByData.length &&
      groupByData.some(
        (item) => Array.isArray(item.tasks) && item.tasks.length,
      ) ? (
        groupByData.map((item) => {
          return Array.isArray(item.tasks) && item.tasks.length > 0 ? (
            <div>
              {groupByTasks === 'team' ? (
                <div className="dFlex groupby-header-text">
                  <Avatar
                    src={item.avatar ?? '/src'}
                    alt={item.first_name ?? 'A'}
                    sx={{
                      width: '30px',
                      height: '30px',

                      border: 'none',
                      '& .css-1ytufz-MuiAvatarGroup-root': {
                        border: 'none',
                      },
                    }}
                  />
                  {item.first_name} {item.last_name}
                </div>
              ) : (
                <p className="groupby-header-text">{item.name}</p>
              )}
              {sureDelete && (
                <ConfirmDeletionModal
                  setSureDelete={setSureDelete}
                  deleteEndPoint={deleteEndPoint}
                  itemName={itemName}
                  paragraph={paragraph}
                  currentTargetData={currentTargetData}
                />
              )}
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    tableLayout: 'fixed',
                    width: '100%',
                  }}
                >
                  <TableHeader groupByTasks={groupByTasks} />
                  <TaskRow
                    tasks={item?.tasks}
                    groupByTasks={groupByTasks}
                    setParams={setParams}
                    setCurrentTarget={setCurrentTarget}
                    setShow={setShow}
                    setGroupByData={setGroupByData}
                    ENDPOINT={ENDPOINT}
                    token={token}
                    setSureDelete={setSureDelete}
                    moreIco={moreIco}
                  />
                </Table>
              </TableContainer>
            </div>
          ) : null;
        })
      ) : (
        <div
          style={{
            height: '90%',
            overflow: 'hidden',
          }}
        >
          <TableContainer component={Paper}>
            <Table
              sx={{
                tableLayout: 'fixed',
                width: '100%',
              }}
            >
              <TableHeader groupByTasks={groupByTasks} />
            </Table>
          </TableContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <h4
              style={{
                color: '#575757',
                fontFamily: 'Apple-System, Arial, Helvetica',
              }}
            >
              It looks like there’s nothing here. Try changing your filters to
              see more results.
            </h4>
          </div>
        </div>
      )}
    </div>
  );
}

export default GroupByTable;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import Bounce from 'react-reveal/Bounce';
import { Menu, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';

import { CSVLink } from 'react-csv';
import { getCompanies } from '../ContactRedux';
import Dots from '../assets/Dots.png';
import ExportIcon from '../assets/ExportIcon.png';
import DeleteIcon from '../assets/DeleteIcon.png';

import HelpVideo from '../../../Componenets/TopNav/HelpVideo';
import { useRouteTo } from '../../../hooks/useRouteTo';
import { appRoutes } from '../../../appRoutes';
// eslint-disable-next-line
import TitleBar from '../../../Componenets/atomic-molecules/Headers/TitleBar';
import NetNetTable from '../../../Componenets/atomic-organisms/Tables/NetNetTable';
import {
  loadColumnsFromLocalStorage,
  saveColumnsToLocalStorage,
} from '../../../cache/columnsPersistence';
import { BrowserStorageManager } from '../../../cache/BrowserStorageManager';

const localStorageManager = new BrowserStorageManager(localStorage);

function OptBtn({
  params,
  setSureDelete,
  setCustomParams,
  updateInnerNetNetTabs,
}) {
  const currentUser = useSelector((state) => state.userData.userData);
  const routeTo = useRouteTo();

  useEffect(() => {
    setCustomParams(params);
  }, []);

  return (
    <Popup
      trigger={
        <button type="button" className="noBorder">
          <img src={params.value} alt="more" />
        </button>
      }
      position="right center"
    >
      <ul className="popup" style={{ width: '150px' }} id={params.id}>
        <li
          className="popupChildren"
          onClick={() => {
            routeTo(`${appRoutes.editCompany}/${params.row.unique_id}`);
            updateInnerNetNetTabs(
              'Edit Company',
              `${appRoutes.editCompany}/${params.row.unique_id}`,
            );
          }}
        >
          Edit
        </li>

        {currentUser.is_owner || currentUser.is_admin ? (
          <li
            className="popupChildren LastChild"
            onClick={() => {
              setCustomParams(params.row);
              setSureDelete(true);
            }}
            style={{ color: 'red' }}
          >
            Delete
          </li>
        ) : null}
      </ul>
    </Popup>
  );
}

function Dropdown({ routeTo, updateInnerNetNetTabs }) {
  return (
    <ul className="menu peopleDrop">
      <li
        className="menu-item popupChildren"
        onClick={() => {
          routeTo(appRoutes.newPerson);
          updateInnerNetNetTabs('New Person', appRoutes.newPerson);
        }}
      >
        New Person
      </li>
      <li
        className="menu-item LastChild popupChildren"
        onClick={() => {
          routeTo(appRoutes.newCompany);
          updateInnerNetNetTabs('New Company', appRoutes.newCompany);
        }}
      >
        New Company
      </li>
      <li
        className="menu-item popupChildren"
        onClick={() => {
          updateInnerNetNetTabs(
            'Import People & Companies',
            appRoutes.importContacts,
          );
          routeTo(appRoutes.importContacts);
        }}
      >
        Import People & Companies
      </li>
    </ul>
  );
}

export { Dropdown };

function Companies({ screenHeight, updateInnerNetNetTabs }) {
  const [sureDelete, setSureDelete] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [customParams, setCustomParams] = useState({
    row: {},
  });

  const [menuIconEl, setMenuIconEl] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [myDropDown, setShowDropDown] = useState(false);
  const routeTo = useRouteTo();
  const currentUser = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [companyDetail] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);

  document.addEventListener('click', (e) => {
    if (e.target.id !== 'c-btn') {
      setShowDropDown(false);
    }
  });

  useEffect(() => {
    dispatch(
      getCompanies({
        access_token,
      }),
    );
  }, []);

  const companies = useSelector((state) => state.Contacts.companies);

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(companies);
  }, [companies]);

  const handleExportToExcel = () => {
    if (selectedCompanies.length > 0) {
      const companiesRecord = selectedCompanies
        .map((cid) => companies.find((company) => company.id === cid))
        .filter((company) => !!company)
        .map(
          ({
            id,
            unique_id,
            name,
            address,
            address_zip,
            linkedin_url,
            facebook_url,
            twitter_url,
            instagram_url,
            contact_type,
            activated,
            category,
            country,
            street,
            city,
            state,
            company_url,
          }) => ({
            id,
            unique_id,
            name,
            address,
            address_zip,
            linkedin_url,
            facebook_url,
            twitter_url,
            instagram_url,
            contact_type,
            activated,
            category,
            country,
            street,
            city,
            state,
            company_url,
            ' ': '',
          }),
        );
      return companiesRecord;
    }
    return [];
  };

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getCompanies(meta));
  }, [sureDelete]);

  const [columns, setColumns] = useState([
    {
      field: 'name',
      headerName: 'Company name',
      width: 250,
      renderHeader: () => <div className="tableHeader">Company Name</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            routeTo(`${appRoutes.companyDetail}/${params.row.unique_id}`);
            updateInnerNetNetTabs(
              'Company Detail',
              `${appRoutes.companyDetail}/${params.row.unique_id}`,
            );
          }}
        >
          {params.value}
        </div>
      ),
      align: 'left',
      resizable: true,
    },
    {
      field: 'web_address',
      headerName: 'Web Address',
      width: 250,
      renderHeader: () => <div className="tableHeader">Web Address</div>,
      renderCell: (params) => {
        return (
          <div className="td">
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (Array.isArray(params.value) && params.value.length) {
                  const url = params.value[0].domain_name;
                  window.open(url, '_blank');
                }
              }}
            >
              {Array.isArray(params.value) && params.value.length
                ? params.value[0].domain_name
                : ''}
            </Box>
          </div>
        );
      },
    },
    {
      field: 'industry',
      headerName: 'NAICS Industry',
      width: 200,
      renderHeader: () => <div className="tableHeader">NAICS Industry</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'officeNum',
      headerName: 'Office Number',
      width: 200,
      renderHeader: () => <div className="tableHeader">Office Number</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'people',
      headerName: 'People',
      width: 150,
      renderHeader: () => <div className="tableHeader">People</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      renderHeader: () => <div className="tableHeader">Type</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'address',
      headerName: 'Address 1',
      width: 200,
      renderHeader: () => <div className="tableHeader">Address 1</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'address2',
      headerName: 'Address 2',
      width: 200,
      renderHeader: () => <div className="tableHeader">Address 2</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200,
      renderHeader: () => <div className="tableHeader">City</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 200,
      renderHeader: () => <div className="tableHeader">State</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 200,
      renderHeader: () => <div className="tableHeader">Country</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'zip',
      headerName: 'Zip Code',
      width: 200,
      renderHeader: () => <div className="tableHeader">Zip Code</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'facebook',
      headerName: 'Facebook',
      width: 200,
      renderHeader: () => <div className="tableHeader">Facebook</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            window.open(params.value);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'instagram',
      headerName: 'Instagram',
      width: 200,
      renderHeader: () => <div className="tableHeader">Instagram</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            window.open(params.value);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'x_twitter',
      headerName: 'X',
      width: 200,
      renderHeader: () => <div className="tableHeader">X</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            window.open(params.value);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'linked_in',
      headerName: 'Linked in',
      width: 200,
      renderHeader: () => <div className="tableHeader">Linked in</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            window.open(params.value);
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'tags',
      headerName: 'Tags',
      width: 200,
      renderHeader: () => <div className="tableHeader">Tags</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'Options',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params) => (
        <div style={{ padding: '0 1rem 0 0' }}>
          <OptBtn
            updateInnerNetNetTabs={updateInnerNetNetTabs}
            params={params}
            setSureDelete={setSureDelete}
            setCustomParams={setCustomParams}
          />
        </div>
      ),
    },
  ]);

  const companySortModelStorageKey = `CompanyTableSortingForUserID${currentUser.id}`;
  const companyColumnOrderStorageKey = `CompanyTableColumnOrderForUserID${currentUser.id}`;

  const [companySortModel, setCompanySortModel] = useState(
    localStorageManager.get(companySortModelStorageKey)
      ? localStorageManager.get(companySortModelStorageKey)
      : [
          {
            field: 'name',
            sort: 'asc',
          },
        ],
  );

  useEffect(() => {
    localStorageManager.set(companySortModelStorageKey, companySortModel);
  }, [companySortModel]);

  const rows = projects.map((company) => ({
    id: company.id,
    name: company.name,
    web_address: company.company_url,
    state: company.state,
    city: company.city,
    Options: Dots,
    unique_id: company.unique_id,
    industry: company.industry,
    officeNum: company.office_num,
    type: company.company_type ?? '--------',
    mobile: company.mobile_num ?? '--------',
    address: company.street ?? '--------',
    address2: company.street2 ?? '--------',
    zip: company.address_zip ?? '--------',
    country: company.country ?? '--------',
    facebook: company.facebook_url ?? '--------',
    x_twitter: company.twitter_url ?? '--------',
    linkedin: company.linkedin_url ?? '--------',
    instagram: company.instagram_url ?? '--------',
    tags: company.tags ?? '--------',
    people:
      Array.isArray(company.company_contacts) && company.company_contacts.length
        ? company.company_contacts.length
        : 0,
  }));

  const searchedRows = searchQuery
    ? rows.filter(
        (item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.city?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          item?.state?.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : rows;

  useEffect(() => {
    setColumns(
      loadColumnsFromLocalStorage(companyColumnOrderStorageKey, columns),
    );
  }, []);

  const handleColumnOrderChange = (newColumnOrder) => {
    const updatedColumns = [...columns.map((item) => ({ ...item }))];
    const columnToMove = updatedColumns[newColumnOrder.targetIndex - 1];
    const find = columns.find(
      (item) => item.field === newColumnOrder.column.field,
    );

    updatedColumns[newColumnOrder.targetIndex - 1] = find;
    updatedColumns[newColumnOrder.oldIndex - 1] = columnToMove;

    saveColumnsToLocalStorage(companyColumnOrderStorageKey, updatedColumns);
    setColumns(updatedColumns);
  };

  const courses = useSelector((state) => state.NetNetU.courses).filter(
    (item) => item.course_type === 'help_videos',
  );
  const welcomeChapters = courses.flatMap((course) => {
    return course.chapters.filter((chapter) => chapter.title === 'Company');
  });
  const [show1, setShow1] = useState(false);

  const baseKey = 'companiesTableColumnsVisibilityForUserID';
  const storageKey = `${baseKey}${currentUser?.id}`;

  const defaultVisibilityModel = {
    address: false,
    address2: false,
    industry: false,
    facebook: false,
    instagram: false,
    linked_in: false,
    x_twitter: false,
    country: false,
    zip: false,
    tags: false,
  };

  const getStoredVisibility = () => {
    const storedSettings = localStorageManager.get(storageKey);
    return storedSettings ?? defaultVisibilityModel;
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    getStoredVisibility(),
  );

  useEffect(() => {
    localStorageManager.set(storageKey, columnVisibilityModel);
  }, [columnVisibilityModel]);

  const handleColumnVisibilityChange = (newVisibilityModel) => {
    setColumnVisibilityModel(newVisibilityModel);
  };

  const itemName = selectedCompanies.length > 1 ? 'Companies' : 'Company';
  const deleteEndPoint =
    selectedCompanies.length > 0
      ? `api/contact/company/delete/`
      : `api/contact/company/delete/${customParams.id}/`;

  return companies.length < 1 ? (
    <section className="oppor-section">
      <TitleBar
        helpIconFunction={() => setShow1(true)}
        sectionTitle="Companies"
        buttonFunction={() => setShowDropDown(!myDropDown)}
        buttonRequired={true}
        buttonTitle="New"
      />
      {myDropDown && (
        <Dropdown
          routeTo={routeTo}
          updateInnerNetNetTabs={updateInnerNetNetTabs}
        />
      )}
      <section className="myCenter" style={{ height: screenHeight - 155 }}>
        <article className="box-txt">
          <h3 className="txtCenter">
            Whoopsy.... You don&apos;t have any Companies just yet!
          </h3>
          <p className="txtCenter initialParagraph">
            That&apos;s okay, you can easily add one at a time or many through
            the import process. Let&apos;s get started, eh?
          </p>
          <article className="buttonGroup">
            <button
              type="button"
              className="btn"
              onClick={() => {
                updateInnerNetNetTabs(
                  'Import People & Companies',
                  appRoutes.importContacts,
                );
                routeTo(appRoutes.importContacts);
              }}
            >
              Import
            </button>
            <button
              type="button"
              className="btn"
              onClick={() => {
                routeTo(appRoutes.newCompany);
                updateInnerNetNetTabs('New Company', appRoutes.newCompany);
              }}
            >
              New Company
            </button>
          </article>
        </article>
      </section>
      <HelpVideo
        show={show1}
        setShow={setShow1}
        welcomeChapters={welcomeChapters}
      />
    </section>
  ) : (
    <Bounce left>
      <section className={!companyDetail ? 'oppor-section' : 'oppor-section'}>
        <TitleBar
          helpIconFunction={() => setShow1(true)}
          sectionTitle="Companies"
          Dotopen={menuIconEl}
          handleDotDropDown={(e) => setMenuIconEl(e.currentTarget)}
          menuDotRequired={true}
          buttonFunction={() => setShowDropDown(!myDropDown)}
          buttonRequired={true}
          buttonTitle="New"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchBarRequired={true}
        />

        {myDropDown && (
          <Dropdown
            routeTo={routeTo}
            updateInnerNetNetTabs={updateInnerNetNetTabs}
          />
        )}

        <Menu
          id="company-icon-menu"
          sx={{
            '& .MuiMenu-paper': {
              border: '1px solid #03071E33',
              borderRadius: '10px',
            },
            '& .MuiMenu-list': {
              margin: '5px 15px 5px 5px',
            },
          }}
          anchorEl={menuIconEl}
          open={!!menuIconEl}
          onClose={() => setMenuIconEl(null)}
          autoFocus={false}
        >
          <MenuItem className="mb-2">
            <CSVLink
              style={{ color: 'black' }}
              enclosingCharacter=""
              data={handleExportToExcel()}
              onClick={() => handleExportToExcel().length > 0}
              filename="Companies.csv"
            >
              <img alt="icon" src={ExportIcon} className="icon-sm" />
              Export Selected
            </CSVLink>
          </MenuItem>
          {currentUser.is_owner || currentUser.is_admin ? (
            <MenuItem
              className="text-danger mb-2"
              onClick={() => {
                setSureDelete(true);
                setMenuIconEl(null);
              }}
            >
              <img alt="icon" src={DeleteIcon} className="icon-sm" />
              Delete Selected
            </MenuItem>
          ) : null}
        </Menu>
        <NetNetTable
          rows={searchedRows}
          columns={columns}
          screenHeight={screenHeight}
          sortModel={companySortModel}
          setSortModel={setCompanySortModel}
          selectRow={selectedCompanies}
          setSelectRow={setSelectedCompanies}
          handleColumnOrderChange={handleColumnOrderChange}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
          sx={{
            '&.MuiDataGrid-columnHeaders': {
              backgroundColor: '#f2f2f2',
            },
            minHeight: screenHeight - 155,
            maxHeight: screenHeight - 155,
            borderRadius: '8px',
            width: '100%',
            minWidth: 1190,
          }}
          checkboxSelection={true}
          initialState={{
            columns: {
              columnVisibilityModel,
            },
          }}
          componentsProps={{
            panel: {
              sx: {
                [`& .MuiDataGrid-columnsPanel > div:first-child`]: {
                  display: 'none',
                },
                [`& .MuiDataGrid-columnsPanel > div:last-child`]: {
                  display: 'none',
                },
              },
            },
          }}
          setSureDelete={setSureDelete}
          sureDelete={sureDelete}
          itemName={itemName}
          paragraph={`Are You Sure You want to Delete
                      ${selectedCompanies.length > 1 ? 'These Companies' : 'This Company'}? 
                      Removing ${selectedCompanies.length > 1 ? 'These Companies' : 'This Company'}
                      will remove all associated data.`}
          deleteEndPoint={deleteEndPoint}
          // eslint-disable-next-line
          multipleDelete={selectedCompanies.length > 0 ? true : false}
          multipleDeleteData={selectedCompanies}
        />
        <HelpVideo
          show={show1}
          setShow={setShow1}
          welcomeChapters={welcomeChapters}
        />
      </section>
    </Bounce>
  );
}
export default Companies;

import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Check } from '@mui/icons-material';
import { BsFillCircleFill } from 'react-icons/bs';
import { colorPalette } from '../../../utils/colors-helper';
import '../atomic-molecules.css';

/**
 * SelectDropdown component.
 *
 * @param {string} id - The unique identifier for the filter.
 * @param {Array} options - Options should be any array which will have options foe the filter.
 * @param {string} defaultValue - Default Value means if it is a multiselect drop down then there would be a default value for filter like the default text. for example Task Type.
 * @param {Array} value - Value would be always array. if its a single select you will send data like this value={[value]}
 * @param {Array} selectedFilter - SelectedFilter is pretty same like a value but it helps in what filter is selected so we can apply styling accordingly.
 * @param {function} onChange - onchange would be a function, can be used like onchange={(e)=> handleFilter(e)}
 */

function SelectDropdown({
  id,
  options,
  defaultValue,
  value,
  selectedFilter,
  onChange,
}) {
  return (
    <FormControl>
      <Select
        labelId={id}
        value={value}
        onChange={onChange}
        className="select-dropdown"
        multiple
        sx={{
          '.css-ve28d2': {
            padding: '0',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        renderValue={(selected) =>
          defaultValue ??
          (Array.isArray(selected) &&
            selected
              .map((val) => options.find((opt) => opt.value === val)?.label)
              .join(', '))
        }
      >
        {id === 'team-filter' && Array.isArray(options) && options.length
          ? options
              .filter((item) => item.first_name !== 'First Name')
              .map((item, index) => {
                return (
                  <MenuItem
                    value={item.id}
                    key={item?.id ?? item?.first_name}
                    className="filter-option"
                    sx={{
                      width: '100%',
                      backgroundColor:
                        Array.isArray(selectedFilter) &&
                        selectedFilter.length &&
                        selectedFilter.includes(item.id)
                          ? '#711FFF'
                          : '#fff',
                      color:
                        Array.isArray(selectedFilter) &&
                        selectedFilter.length &&
                        selectedFilter.includes(item.id)
                          ? '#fff'
                          : '#000',
                      borderBottom:
                        index === 0 ? '1px solid var(--primary-color)' : '',
                      marginBottom: index === 0 ? '2px' : '0',
                    }}
                  >
                    <Check
                      className="tick-icon"
                      sx={{
                        color:
                          Array.isArray(selectedFilter) &&
                          selectedFilter.length &&
                          selectedFilter.includes(item.id)
                            ? '#6eff78'
                            : '#fff',
                      }}
                    />{' '}
                    {`${item.first_name ?? ''} ${item.last_name ?? ''}`}
                  </MenuItem>
                );
              })
          : options.map((option, index) => (
              <MenuItem
                key={id === 'jobs-filter' ? option.label : option.value}
                value={
                  id === 'jobs-filter'
                    ? { type: option.type, value: option.value }
                    : option.value
                }
                className="filter-option"
                sx={{
                  backgroundColor:
                    id === 'jobs-filter'
                      ? Array.isArray(selectedFilter) &&
                        selectedFilter.length &&
                        selectedFilter.some(
                          (ele) =>
                            ele.value === option.value &&
                            ele.type === option.type,
                        )
                        ? '#711FFF'
                        : '#fff'
                      : Array.isArray(selectedFilter) &&
                          selectedFilter.length &&
                          selectedFilter.includes(option.value)
                        ? '#711FFF'
                        : '#fff',
                  color:
                    id === 'jobs-filter'
                      ? Array.isArray(selectedFilter) &&
                        selectedFilter.length &&
                        selectedFilter.some(
                          (ele) =>
                            ele.value === option.value &&
                            ele.type === option.type,
                        )
                        ? '#fff'
                        : '#000'
                      : Array.isArray(selectedFilter) &&
                          selectedFilter.length &&
                          selectedFilter.includes(option.value)
                        ? '#fff'
                        : '#000',
                  borderBottom:
                    defaultValue && index === 0
                      ? '2px solid var(--primary-color)'
                      : '',
                  marginBottom: defaultValue && index === 0 ? '2px' : '0',
                  '&:hover': {
                    textDecoration: 'none',
                    backgroundColor:
                      Array.isArray(selectedFilter) &&
                      selectedFilter.length &&
                      selectedFilter.some(
                        (ele) =>
                          ele.value === option.value &&
                          ele.type === option.type,
                      )
                        ? '#711FFF'
                        : '#f0f0f0',
                    color:
                      Array.isArray(selectedFilter) &&
                      selectedFilter.length &&
                      selectedFilter.some(
                        (ele) =>
                          ele.value === option.value &&
                          ele.type === option.type,
                      )
                        ? '#fff'
                        : '#000',
                  },
                }}
              >
                {defaultValue ? (
                  <Check
                    className="tick-icon"
                    sx={{
                      color:
                        id === 'jobs-filter'
                          ? Array.isArray(selectedFilter) &&
                            selectedFilter.length &&
                            selectedFilter.some(
                              (ele) =>
                                ele.value === option.value &&
                                ele.type === option.type,
                            )
                            ? '#6eff78'
                            : '#fff'
                          : Array.isArray(selectedFilter) &&
                              selectedFilter.length &&
                              selectedFilter.includes(option.value)
                            ? '#6eff78'
                            : '#fff',
                    }}
                  />
                ) : null}{' '}
                {option.value === 0 ? (
                  <BsFillCircleFill
                    style={{ color: '#e1e1e1', marginRight: '5px' }}
                  />
                ) : option.value === 80 ? (
                  <BsFillCircleFill
                    style={{
                      color: colorPalette.actualGreenColor,
                      marginRight: '5px',
                    }}
                  />
                ) : option.value === 81 ? (
                  <BsFillCircleFill
                    style={{
                      color: colorPalette.actualOrangeColor,
                      marginRight: '5px',
                    }}
                  />
                ) : option.value === 100 ? (
                  <BsFillCircleFill
                    style={{
                      color: colorPalette.actualRedColor,
                      marginRight: '5px',
                    }}
                  />
                ) : null}
                {option.label}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
}

// eslint-disable-next-line
export { SelectDropdown };

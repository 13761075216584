// eslint-disable-next-line
export const getTaskSectionTitle = (args) => {
  if (args.length === 0) {
    return 'Tasks';
  }

  if (args.includes('all')) {
    return 'All Tasks';
  }

  if (
    args.includes('quick_task') &&
    args.includes('project_task') &&
    args.includes('project_task_retainer')
  ) {
    return 'Quick, Job & Retainer Tasks';
  }

  if (args.includes('quick_task') && args.includes('project_task')) {
    return 'Quick & Job Tasks';
  }

  if (args.includes('quick_task') && args.includes('project_task_retainer')) {
    return 'Quick & Retainer Tasks';
  }

  if (args.includes('project_task') && args.includes('project_task_retainer')) {
    return 'Job & Retainer Tasks';
  }

  if (args.includes('project_task_retainer')) {
    return 'Retainer Tasks';
  }

  if (args.includes('project_task')) {
    return 'Job Tasks';
  }

  if (args.includes('quick_task')) {
    return 'Quick Tasks';
  }

  return 'Tasks';
};
